import axios from 'axios'
import util from "../Util"
import errorCode from './errorCode'
import {
  Message,
  MessageBox
} from 'element-ui'
import qs from 'qs'

function getToken(key) {
  var val = localStorage.getItem(key) || ''
  return val

}

function logOut() {
  // let dom = document.getElementsByClassName('el-message-box__wrapper')
  if (location.pathname != "/login") {
    MessageBox.alert('离开太久 , 或登录失效 , 请重新登录 !', '提示', {
      confirmButtonText: '确定',
      type: 'warning',
      center: true
    }).then(() => {
      localStorage.removeItem('access_token')
      location.href = '/login'
    }).catch(() => {
      // dom[0].remove()
    });
  }
}
axios.defaults.timeout = 50000
// 返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500 // 默认的
}
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true

// HTTPrequest拦截
// axios.interceptors.request.use(config => {
//   const isToken = (config.headers || {}).isToken === false
//   let token = getToken('access_token')
//   if (token && !isToken) {
//     config.headers['Authorization'] =   token // token
//   }
//   // headers中配置serialize为true开启序列化
//   if (config.methods === 'post' && config.headers.serialize) {
//     config.data = util.serialize(config.data)
//     delete config.data.serialize
//   }

//   // 处理get 请求的数组 springmvc 可以处理
//   if (config.method === 'get') {
//     config.paramsSerializer = function (params) {
//       return qs.stringify(params, {
//         arrayFormat: 'repeat'
//       })
//     }
//   }
//   return config
// }, error => {
//   return Promise.reject(error)
// })


// HTTPresponse拦截
axios.interceptors.response.use(res => {
  const status = Number(res.status)
  const message = res.data.msg || errorCode[status] || errorCode['default']
  if (status === 401) {
    logOut()
    return
  }

  if (status !== 200 || res.data.code === 1) {
    Message({
      message: message,
      type: 'error'
    })
    return Promise.reject(new Error(message))
  }
  return res
}, error => {

  return Promise.reject(new Error(error))
})

export default function request(config, data = {}, bool = false) {
  config = config
  // console.log("请求参数：", data, bool)
  // access_token请求必须的
  let date = new Date()
  let operatorId = ""
  let seq = "0001"
  let newData = util.Encrypt(JSON.stringify(data))
  let time = util.getTimeStamp(date)
  let text = operatorId + newData + time + seq
  let sign = util.HMACShaEncrypt(text) //HmacMD5(  operatorId+data+timeStamp+seq)
  let aesData = {
    "data": newData,
    "operatorId": operatorId,
    "seq": seq,
    "sign": sign,
    "timeStamp": time //"20210122171030"
  }
  const Public = bool ? data : aesData
  // console.log(Public, "Public")
  let httpDefaultOpts = {
    method: config.method || 'POST',
    url: util.baseUrl + config.url,
    headers: {
      Authorization: config.needToken ? getToken('access_token') : 'Basic cGlnOnBpZw==',
      isToken: false,
    },
    data: data
  }
  return new Promise((resolve, reject) => {
    axios(httpDefaultOpts)
      .then(res => {
        // res.data.data = res.data.data && JSON.parse(util.Decrypt(res.data.data))
        // console.log(config.url, "返回结果：", res.data)
        if (res.data.code === 0) {
          resolve(res.data)
        } else if (res.data.code == '4001') {
          logOut()
        } else {
          resolve(res.data)
          util.message("err", res.data.msg)
        }
      })
      .catch(err => {
        reject(err)
      })
  })

}