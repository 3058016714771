<template>
  <div class="app">
    <!--路由出口  -->
    <!-- <navigation> -->
    <router-view></router-view>
    <!-- </navigation> -->
  </div>
</template>

<script>
  export default {}
</script>

<style lang="less">
  .din_bold {
    font-family: "din-bold";
  }

  .din {
    font-family: "din";
  }

  .tox-tinymce-aux {
    z-index: 10000 !important;
  }
  
   .tox-notification--warning {
    display: none !important;
  }

  .tox .tox-notification--in {
    display: none !important;
  }

  .amap-logo {
    opacity: 0;
  }

  .amap-copyright {
    opacity: 0;
  }
</style>