import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VuexPersistence from 'vuex-persistedstate'
import {
  judgeOss
} from "../api/api"
import request from "../api/request"
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [vuexLocal],
  state: {
    userInof: {}, //用户信息
    baseInfo: {}, //心理档案需要
    previousForm: null, //上一页的查询条件
    doctorTemplate: '', //测评结果echart显示状态
    uploadStyle: "0", //上传文件方式，0本地 1 oss 2七牛
    version: '1.0.0', //版本号,
    currentMenuId: '', //点击保存当前的菜单id
    poewrMenuIdList: [], //权限菜单获取页面按钮menuId显示列表
    menuList: [], //左边菜单列表
    gaugeAddForm: { //新增量表参数缓存
      topic: {
        mainName: "", //量表名称
        mainPhoto: "", //量表图标
        mainMemo: "", //量表描述
        categoryId: "", //量表分类 
      },
      // isPublic: "", //量表是否公开,1公开 0不公开
      subjects: [], //量表题目
      formula: {
        formulaName: "", //公式名称
        roundType: 0, //取整算法 （0保持原值 1向下取整 2向上取整 3四舍五入 4不取整保留2位小数）
        formulaExpr: "", //算法公式
        showSubFactors: "", //显示 二级因子配置
        showFilters: "", //显示 效度量表配置
        subFactors: "", //二级因子配置
        filters: "", //效度量表配置
      },
      sum: 0, //每题总分
      fullMarks: 0, //满分
      evaluationList: [], //测评结果列表
      noAllMeun:false,//大屏去掉所有菜单
      schemes: [], //测评结果列表
    },
  },
  mutations: {
    setParam(state, payload) {
      for (let i in payload) {
        state[i] = payload[i]
      }
    },
    // 权限菜单获取menuId显示列表
    poewrMenuIdListFn(state, data) {
      state.poewrMenuIdList = data
    },
    // 权限菜单获取menuId显示列表
    currentMenuIdFn(state, data) {
      state.currentMenuId = data
    },
    menuListFn(state, data) {
      state.menuList = data
    },
  },
  actions: {
    // 判断是否用oss
    async judgeOssFn({
      commit
    }) {
      const res = await request(judgeOss)
      if (res.code == 200) {
        let oss_channel = res.data.oss_channel
        commit('setParam', {
          uploadStyle: oss_channel
        })
      }
    }
  }
})