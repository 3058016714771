
import request from "./request"
import store from '../store/index'
import router from "@/router/index.js"
import { userMenu } from "./api";

// 获取左菜单权限列表
const common =  {
 getUserMenuList() {
    return new Promise((resolve) => {
        request(userMenu).then((res) => {
        if (res.code == "200") {
          if(res.data.length>0){
            let data = res.data
            let parentList = []
            let sonList = []
            data.find((val)=>{
              if(val.menuFuntype == 0){
                val.children = []
                parentList.push(val)
              }else{
                 sonList.push(val)
              }
            })
              parentList.find((item)=>{
                sonList.find((val)=>{
                  if( item.menuId == val.menuParent ){
                    item.children.push(val) 
                  }
                
              })
             
              })
              store.commit('menuListFn',[])
              store.commit('menuListFn', parentList?parentList:[])
            }else{
              store.commit('menuListFn',[])
              this.$router.push({path:'/panel/home'})
            }
          return
        }
        resolve(false)
      }).catch(() => {
        resolve(false)
      })
    })
  }
} 
  export default common