import Vue from "vue"
import App from "./App.vue"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import "./assets/less/common.less"
import store from './store'
import router from "@/router/index.js"
import downLoad from "./api/downLoad"
import request from "./api/request"
import common from "./api/common"
import util from "./Util"
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import axios from 'axios'
import Navigation from 'vue-navigation'
import 'babel-polyfill'
import dataV from '@jiaminghi/data-view'
import VueWordcloud from 'vue-b2wordcloud'
import "./common/font/din-bold.css";
import "./common/font/din.css";
import Print from 'vue-printjs'
Date.prototype.format = function format (pattern) {
  return util.format(this, pattern)
}

Vue.use(Print)
Vue.use(VueWordcloud)
Vue.use(dataV)
Vue.use(Navigation, {
  router
})
Vue.use(ElementUI)
Vue.use(VideoPlayer)

Vue.config.productionTip = false

Vue.prototype.$request = request
Vue.prototype.$downLoad = downLoad
Vue.prototype.$axios = axios
Vue.prototype.$util = util
Vue.prototype.$common = common
Vue.config.productionTip = false

/* eslint-disable no-new  */
// 注入到new vue

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")