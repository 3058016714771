  // const baseURL = ''
  import util from "../Util"
  const needToken = true
  // 登录
  export const login = (data) => {
    return {
      method: 'get',
      url: `/auth/oauth/token?username=${data.username}&password=${data.password}&randomStr=${data.randomStr}&code=${data.code}&grant_type=password&scope=server`,
    }
  }
  // 修改密码
  export const updatePWD = {
    url: '/sys/login/modifyPWD',
    needToken
  }
  // 获取验证码图片
  export const codeImg = (num) => {
    return {
      method: 'get',
      url: `/code?randomStr=${num}`,
      needToken
    }
  }
  // 用户登出
  export const logout = {
    url: '/auth/token/logout',
    needToken
  }
  //获取用户信息
  export const userInfo = {
    method: 'get',
    url: `/admin/user/info`,
    needToken
  }
  // // 判断上传图片使用oss还是本地库,返回的oss_enable:0本地 1 oss 2七牛
  // export const judgeOss = {
  //   method: 'get',
  //   url: `/api/v1/sys/config/info/oss_channel`,
  //   needToken
  // }
  // export const judgeminio = {
  //   method: 'get',
  //   url: `/api/v1/sys/config/info/oss_minio`,
  //   needToken
  // }

  // // 获取阿里云key
  // export const getpolicy = {
  //   method: 'get',
  //   url: '/api/v1/oss/aliyun/getpolicy',
  //   needToken
  // }
  // //七牛云 type 为1是图片  2是视频
  // export const qiniuyun = {
  //   url: `/api/v2/oss/qiniuyun/gettoken`,
  //   needToken
  // }
  // 批量上传文件 type: 1上传图片  2视频 3音频
  export const fileList1 = {
    url: `/system/file/uploadBatch?type=1`,
    needToken
  }
  export const fileList2 = {
    url: `/system/file/uploadBatch?type=2`,
    needToken
  }
  export const fileList3 = {
    url: `/system/file/uploadBatch?type=3`,
    needToken
  }
  // 上传文件(element) type: 1上传图片  2视频 3音频
  export const files1 = {
    url: `${util.baseUrl}/system/file/upload?type=1`,
    needToken
  }
  export const files2 = {
    url: `${util.baseUrl}/system/file/upload?type=2`,
    needToken
  }
  export const files3 = {
    url: `${util.baseUrl}/system/file/upload?type=3`,
    needToken
  }
  //富文本上传
  export const files = (type) => {
    return {
      url: `/system/file/upload?type=${type}`,
      needToken
    }

  }
  // 返回省市区三级
  export const areaLevel = {
    url: '/system/areas/list',
    needToken
  }
  // 获取用户菜单
  export const userMenu = {
    url: '/api/v1/newmenu/userMenuV1',
    needToken
  }

  // // 获取用户菜单按钮列表/判断按钮权限
  // export const userListMenuId = (menuId) => {
  //   return {
  //     url: `/api/v1/newmenubutton/userListV1/${menuId}`,
  //     needToken
  //   }
  // }
  // 首页/概览
  export const findSum = {
    url: '/oprun/findSum',
    needToken
  }
  // 首页/前十列表
  export const indexTop = {
    url: '/oprun/indexTop',
    needToken
  }
  // 首页/最近7天
  export const indexNum = {
    url: '/oprun/indexNum',
    needToken
  }

  //量表管理/量表列表
  export const topicList = (current, size) => {
    return {
      url: `/topic/topic/page?current=${current}&size=${size}`,
      needToken
    }
  }
  // 量表管理/量表信息/新增
  export const gaugeSave = {
    url: '/topic/topic/save',
    needToken
  }
  // 量表管理/量表信息/编辑
  export const gaugeEdit = {
    url: '/topic/topic/update',
    needToken
  }
  // 量表管理/量表/单个量表（量表信息）查询
  export const gaugeInfo = {
    url: `/topic/topic/getById`,
    needToken
  }
  //查询数据字典 1.科普文章 2.科普视频 3.科普音乐 4.测评量表 5.咨询 6.课堂
  export const topicTypes = {
    url: `/psy/type/getByTheme`,
    needToken
  }

  // 量表管理/量表/单个量表（量表题目）查询||查看
  export const gaugeQuestionsList = {
    url: `/topic/topicQuestion/list`,
    needToken
  }
  // 量表管理/量表/单个量表（量表题目）新增
  export const gaugeQuestionsAdd = {
    url: '/topic/topicQuestion/save',
    needToken
  }
  // 量表管理/量表/单个量表（量表题目）编辑
  export const gaugeQuestionsEdit = {
    url: '/topic/topicQuestion/update',
    needToken
  }
  // 量表管理/量表/单个量表（量表题目）删除
  export const gaugeQuestionsDel = {
    url: `/topic/topicQuestion/del`,
    needToken
  }
  // 量表管理/测评标准/查询量表因子信息
  export const topicFactorInfo = {
    url: `/topic/factor/get_root`,
    needToken

  }
  // 量表管理/测评标准/新增
  export const topicFactorSave = {
    url: `/topic/factor/save`,
    needToken
  }
  // 量表管理/测评标准/编辑
  export const topicFactorUpdate = {
    url: `/topic/factor/update`,
    needToken
  }
  // 量表管理/测评标准/删除
  export const topicFactorDel = {
    url: `/topic/factor/del`,
    needToken
  }
  // 量表管理/测评标准/新增因子结论
  export const schemerSave = {
    url: `/topic/factor/scheme/save`,
    needToken
  }
  // 量表管理/测评标准/编辑因子结论
  export const schemerUpdate = {
    url: `/topic/factor/scheme/update`,
    needToken
  }
  // 量表管理/测评标准/删除因子结论
  export const schemeDel = {
    url: `/topic/factor/scheme/del`,
    needToken
  }
  // 量表管理/量表/计算公式
  export const sampleFormula = {
    url: '/topic/factorFormula/getSampleFormula',
    needToken
  }
  // 量表管理/量表/公式配置
  export const formulaConfig = {
    url: '/topic/factorFormula/getFormulaConfig',
    needToken
  }
  // // 量表管理/量表/单个量表（量表标准）新增
  // export const gaugeSchemesAdd = {
  //   url: '/api/v1/topic/save_schemes',
  //   needToken
  // }
  // // 量表管理/量表/单个量表（量表标准）编辑
  // export const gaugeSchemesEdit = {
  //   url: '/api/v1/topic/update_schemes',
  //   needToken
  // }
  // // 量表管理/量表/单个量表（量表标准）删除
  // export const gaugeSchemesDel = {
  //   url: `/api/v1/topic/delete_schemes`,
  //   needToken
  // }
  //量表管理/套餐设置/列表
  export const packageList = (current, size) => {
    return {
      url: `/topic/topicMain/page?current=${current}&size=${size}`,
      needToken
    }
  }
  // 量表管理/套餐设置/详情
  export const packageInfo = {
    url: `/topic/topicMain/getById`,
    needToken
  }
  // 量表管理/套餐设置/新增
  export const savePackage = {
    url: `/topic/topicMain/save`,
    needToken
  }
  // 量表管理/套餐设置/修改
  export const updatePackage = {
    url: `/topic/topicMain/update`,
    needToken
  }
  // 量表管理/套餐设置/修改/查询量表信息
  export const topicLists = {
    url: `/topic/topic/list`,
    needToken
  }
  // 量表管理/套餐设置/删除
  export const delPackage = {
    url: `/topic/topicMain/del`,
    needToken
  }
  // 科普知识
  export const missionExamList = (current, size) => {
    return {
      url: `/psy/popInfo/page?current=${current}&size=${size}`,
      needToken
    }
  }
  // 科普知识/新增
  export const addPsy = {
    url: '/psy/popInfo/save',
    needToken
  }

  // 科普知识/编辑
  export const updatePsy = {
    url: '/psy/popInfo/update',
    needToken
  }
  // 科普知识/查看详情
  export const psyInfo = {
    url: `/psy/popInfo/getById`,
    needToken
  }
  //科普知识/上架 1待审核 2待上架 3上架 4下架
  export const bannerState = {
    url: `/psy/popInfo/status`,
    needToken
  }
  // 科普知识/删除
  export const deletePsy = {
    url: `/psy/popInfo/del`,
    needToken
  }
  //课时管理/列表
  export const courseTimeList = (current, size) => {
    return {
      url: `/psy/courseSource/page?current=${current}&size=${size}`,
      needToken
    }
  }
  //课时管理/查询编辑信息
  export const courseTimeInfo = {
    url: `/psy/courseSource/getById`,
    needToken
  }
  //课时管理/新增
  export const courseTimeSave = {
    url: '/psy/courseSource/save',
    needToken
  }
  //课时管理/编辑
  export const courseTimeUpdate = {
    url: '/psy/courseSource/update',
    needToken
  }
  //课时管理/删除
  export const deleteCourseTime = {
    url: `/psy/courseSource/del`,
    needToken
  }
  //课时管理/上架下架
  export const courseTimeStatus = {
    url: `/psy/courseSource/status`,
    needToken
  }

  //在线课堂/列表
  export const getCourseList = (current, size) => {
    return {
      url: `/psy/course/page?current=${current}&size=${size}`,
      needToken
    }
  }
  //在线课堂/查询编辑信息
  export const courseInfo = {
    url: `/psy/course/getById`,
    needToken
  }
  //在线课堂/新增
  export const courseSave = {
    url: '/psy/course/save',
    needToken
  }
  //在线课堂/编辑
  export const courseUpdate = {
    url: '/psy/course/update',
    needToken
  }
  //在线课堂/删除
  export const deleteCourse = {
    url: `/psy/course/del`,
    needToken
  }
  //在线课堂/上架下架
  export const courseUpdateStatus = {
    url: `/psy/course/status`,
    needToken
  }
  //在线课堂/课程分类
  export const courseType = {
    url: `/system/dictData/list`,
    needToken
  }
  //在线课堂/云视频
  export const couldList = {
    url: `/psy/courseSource/list`,
    needToken
  }
  //机构管理/机构维护/列表
  export const getMerchantList = (current, size) => {
    return {
      url: `/merchant/merchant/page?current=${current}&size=${size}`,
      needToken
    }
  }
  //机构管理/机构维护/查询编辑信息
  export const merchantInfo = {
    url: `/merchant/merchant/getById`,
    needToken
  }
  //机构管理/机构维护/新增
  export const merchantSave = {
    url: '/merchant/merchant/save',
    needToken
  }
  //机构管理/机构维护/编辑
  export const merchantUpdate = {
    url: '/merchant/merchant/update',
    needToken
  }
  //机构管理/机构维护/修改状态
  export const merchantStatus = {
    url: '/merchant/merchant/status',
    needToken
  }
  //机构管理/机构维护/学校管理-新增
  export const merchantSchoolSave = {
    url: '/system/school/save',
    needToken
  }
  //机构管理/机构维护/学校管理-编辑
  export const merchantSchoolUpdate = {
    url: '/system/school/update',
    needToken
  }
  //机构管理/机构维护/学校管理-列表
  export const merchantSchoolList = (current, size) => {
    return {
      url: `/system/school/getSchoolManager?current=${current}&size=${size}`,
      needToken
    }
  }
  //机构管理/机构维护/学校管理-检查该管理员账号是否已存在
  export const merchantSchoolCheck = {
    url: '/system/school/check',
    needToken
  }
  //机构管理/机构维护/学校管理-查询单个
  export const merchantSchoolInfo = {
    url: '/system/school/getById',
    needToken
  }
  //机构管理/机构账户/列表
  export const merUserList = (current, size) => {
    return {
      url: `/merchant/merchant/userPage?current=${current}&size=${size}`,
      needToken
    }
  }
  //资源库/列表
  export const getFileList = (current, size) => {
    return {
      url: `/system/file/page?current=${current}&size=${size}`,
      needToken
    }
  }
  //资源库/查询编辑信息
  export const fileInfo = {
    url: `/system/file/getById`,
    needToken
  }
  //资源库/新增
  export const fileSave = {
    url: '/system/file/save',
    needToken
  }
  //资源库/编辑
  export const fileUpdate = {
    url: '/system/file/update',
    needToken
  }
  //资源库/删除
  export const deleteFile = {
    url: `/system/file/del`,
    needToken
  }
  //资源配置/列表
  export const mchCloudConfigList = {
    url: `/merchant/mchCloudConfig/list`,
    needToken
  }
  //资源配置/分类删除
  export const ConfigDel = {
    url: '/merchant/mchCloudConfig/del',
    needToken
  }
  //资源配置/资源删除
  export const mchCloudItemDel = {
    url: '/merchant/mchCloudItem/del',
    needToken
  }
  //资源配置/分类新增
  export const ConfigSave = {
    url: '/merchant/mchCloudConfig/save',
    needToken
  }
  //资源配置/资源新增
  export const mchCloudItemSave = {
    url: '/merchant/mchCloudItem/save',
    needToken
  }
  //数据字典/心理科普 科普 量表 课堂 上报7
  export const psyTypeList = (current, size) => {
    return {
      url: `/psy/type/page?current=${current}&size=${size}`,
      needToken
    }
  }
  //数据字典/排序
  export const themetypeBatchOrder = {
    url: `/psy/type/sort`,
    needToken
  }

  //数据字典/心理科普/查询编辑信息
  export const typeInfo = {
    url: `/psy/type/getById`,
    needToken
  }
  //数据字典/心理科普//新增
  export const typeSave = {
    url: '/psy/type/save',
    needToken
  }
  //数据字典/心理科普/编辑
  export const typeUpdate = {
    url: '/psy/type/update',
    needToken
  }
  //数据字典/心理科普/上架下架
  export const typeStatus = {
    url: `/psy/type/status`,
    needToken
  }

  //数据字典/心理机构地图/列表
  export const getPsyInstList = (current, size) => {
    return {
      url: `/psy/psyInstitution/page?current=${current}&size=${size}`,
      needToken
    }
  }
  //数据字典/心理机构地图//查询编辑信息
  export const psyInstInfo = {
    url: `/psy/psyInstitution/getById`,
    needToken
  }
  //数据字典/心理机构地图//新增
  export const psyInstSave = {
    url: '/psy/psyInstitution/save',
    needToken
  }
  //数据字典/心理机构地图//编辑
  export const psyInstUpdate = {
    url: '/psy/psyInstitution/update',
    needToken
  }
  //数据字典/心理机构地图//删除
  export const deletePsyInst = {
    url: `/psy/psyInstitution/del`,
    needToken
  }
  //数据字典/心理机构地图/上架下架
  export const psyInstStatus = {
    url: `/psy/psyInstitution/status`,
    needToken
  }
  //数据字典/学校库/列表
  export const getSchoolList = (current, size) => {
    return {
      url: `/system/school/page?current=${current}&size=${size}`,
      needToken
    }
  }
  //数据字典/学校库/查询编辑信息
  export const schoolInfo = {
    url: `/system/school/getById`,
    needToken
  }
  //数据字典/学校库/新增
  export const schoolSave = {
    url: '/system/school/save',
    needToken
  }
  //数据字典/学校库/编辑
  export const schoolUpdate = {
    url: '/system/school/update',
    needToken
  }
  //数据字典/学校库/删除
  export const deleteSchool = {
    url: `/system/school/del`,
    needToken
  }
  //数据字典/学校库/上架下架
  export const schoolStatus = {
    url: `/system/school/status`,
    needToken
  }
  //数据字典/援助热线/列表
  export const getHotLineList = (current, size) => {
    return {
      url: `/system/hotLine/page?current=${current}&size=${size}`,
      needToken
    }
  }
  //数据字典/心理机构地图//查询编辑信息
  export const hotLineInfo = {
    url: `/system/hotLine/getById`,
    needToken
  }
  //数据字典/援助热线/新增
  export const hotLineSave = {
    url: '/system/hotLine/save',
    needToken
  }
  //数据字典/援助热线/编辑
  export const hotLineUpdate = {
    url: '/system/hotLine/update',
    needToken
  }
  //数据字典/援助热线/删除
  export const deleteHotLine = {
    url: `/system/hotLine/del`,
    needToken
  }
  //数据字典/援助热线/获取区域接口
  export const childAreas = {
    url: `/system/areas/childAreas`,
    needToken
  }

  //其他配置/列表(1:直播;2:咨询热线)
  export const getConfigList = (current, size) => {
    return {
      url: `/system/otherConfig/page?current=${current}&size=${size}`,
      needToken
    }
  }

  //其他配置/查询编辑信息
  export const merchantList = {
    url: `/merchant/merchant/list`,
    needToken
  }
  //其他配置/查询编辑信息
  export const configInfo = {
    url: `/system/otherConfig/getById`,
    needToken
  }
  //其他配置/新增
  export const configSave = {
    url: '/system/otherConfig/save',
    needToken
  }
  //其他配置/编辑
  export const configUpdate = {
    url: '/system/otherConfig/update',
    needToken
  }
  //其他配置/删除
  export const sysConfigDel = {
    url: `/system/otherConfig/del`,
    needToken
  }