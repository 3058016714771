import axios from 'axios'
import {
  cache
} from './cache'
// import {
//   refresh_token
// } from './api'
import util from "../Util"
var baseUrl = '/test'

function getToken(key) {
  var timestamp = Date.parse(new Date()) / 1000
  var val = localStorage.getItem(key) || ''
  var tmp = val.split('|')
  if (!tmp[1] || timestamp >= tmp[1]) {
    localStorage.removeItem(key)
    return false
  } else {
    return tmp[0]
  }
}

export default function request(config, data = {}) {
  const Public = data
  var httpDefaultOpts
  if (config.needToken) {
    if (getToken('access_token')) {
      httpDefaultOpts = {
        method: config.method || 'POST',
        url: baseUrl + config.url,
        headers: {
          Authorization: config.needToken ? getToken('access_token') : '',
          uid:localStorage.getItem('uid')?localStorage.getItem('uid'):''
        },
        responseType: "blob",
        data: Public
      }
    // } else if (getToken('refresh_token')) {
    //   httpDefaultOpts = {
    //     method: config.method || 'POST',
    //     url: baseUrl + config.url,
    //     headers: {
    //       BARER: config.needToken ? getToken('refresh_token') : '',
    //       uid:localStorage.getItem('uid')?localStorage.getItem('uid'):''
    //     },
    //     responseType: "blob",
    //     data: Public
    //   }
    }
  } else {
    httpDefaultOpts = {
      method: config.method || 'POST',
      url: baseUrl + config.url,
      headers: {},
      responseType: "blob",
      data: Public
    }
  }
  axios.interceptors.response.use(
    response => {
      response.headers.access_token ?
        cache('access_token', response.headers.access_token) :
        ''
      // response.headers.refresh_token ?
      //   cache('refresh_token', response.headers., 3600 * 24 * 15) :
      //   ''
      return response
    },
    error => {
      if (error.status == 401) {
        location.href = '/login'
      }
      // 错误提醒
      return Promise.reject(error.response)
    }
  )
  return new Promise((resolve, reject) => {
    axios(httpDefaultOpts)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}