//1. 安装路由
// 2. 导入路由
import VueRouter from 'vue-router'
//3. 注册路由
import Vue from 'vue'
Vue.use(VueRouter)
// 4. 路由实例化
const router = new VueRouter({
  /* 配置路由 */
  mode: 'history',
  routes: [{
      path: '/',
      name: 'index',
      component: resolve => require(['@/page/login/login'], resolve)
    },
    {
      path: '/login',
      name: 'login',
      component: resolve => require(['@/page/login/login'], resolve)
    },
    {
      path: '/panel',
      name: 'panel',
      component: resolve => require(['@/page/panel'], resolve),
      children: [{
          path: 'home',
          name: 'home',
          component: resolve => require(['@/page/home/index'], resolve),
        },
        {
          title:"量表",
          path: 'gauge',
          name: 'gauge',
          component: resolve => require(['@/page/gaugeManagement/gauge/index'], resolve),
        },
        {
          title:"量表",
          path: 'gaugeAdd/:id',
          name: 'gaugeAdd',
          component: resolve => require(['@/page/gaugeManagement/gauge/add'], resolve),
        }, {
          title:"套餐设置",
          path: 'packageSet',
          name: 'packageSet',
          component: resolve => require(['@/page/gaugeManagement/packageSet/index'], resolve),
        }, {
          title:"科普知识",
          path: 'science',
          name: 'science',
          component: resolve => require(['@/page/science/index'], resolve),
        }, {
          title:"科普知识",
          path: 'addScience/:id',
          name: 'addScience',
          component: resolve => require(['@/page/science/addScience'], resolve),
        }, {
          title:"放松音乐",
          path: 'music',
          name: 'music',
          component: resolve => require(['@/page/music/index'], resolve),
        }, {
          title:"咨询师管理",
          path: 'consultant',
          name: 'consultant',
          component: resolve => require(['@/page/consultant/index'], resolve),
        }, {
          title:"咨询师管理/编辑",
          path: 'addConsultant/:id',
          name: 'addConsultant',
          component: resolve => require(['@/page/consultant/addConsultant'], resolve),
        }, {
          title:"资源配置",
          path: 'resourceSet',
          name: 'resourceSet',
          component: resolve => require(['@/page/resourceSet/index'], resolve),
        },{
          title:"音频库",
          path: 'audio',
          name: 'audio',
          component: resolve => require(['@/page/resourcePool/audio/index'], resolve),
        },{
          title:"音频库",
          path: 'addAudio',
          name: 'addAudio',
          component: resolve => require(['@/page/resourcePool/audio/addAudio/index'], resolve),
        }, {
          title:"视频库",
          path: 'video',
          name: 'video',
          component: resolve => require(['@/page/resourcePool/video/index'], resolve),
        },{
          title:"视频库",
          path: 'addVideo',
          name: 'addVideo',
          component: resolve => require(['@/page/resourcePool/video/addVideo/index'], resolve),
        }, {
          title:"机构维护",
          path: 'maintain',
          name: 'maintain',
          component: resolve => require(['@/page/organizational/maintain/index'], resolve),
        }, {
          title:"学校管理",
          path: 'maintainSchool/:id',
          name: 'maintainSchool',
          component: resolve => require(['@/page/organizational/maintain/school'], resolve),
        },{
          title:"机构账户",
          path: 'orgAccount',
          name: 'orgAccount',
          component: resolve => require(['@/page/organizational/orgAccount/index'], resolve),
        },{
          title:"数据字典/服务分类",
          path: 'serviceType',
          name: 'serviceType',
          component: resolve => require(['@/page/dictionary/serviceType/index'], resolve),
        },{
          title:"数据字典/科普分类",
          path: 'psyType',
          name: 'psyType',
          component: resolve => require(['@/page/dictionary/psyType/index'], resolve),
        },{
          title:"数据字典/量表分类",
          path: 'gaugeType',
          name: 'gaugeType',
          component: resolve => require(['@/page/dictionary/gaugeType/index'], resolve),
        },{
          title:"数据字典/课堂分类",
          path: 'coursesType',
          name: 'coursesType',
          component: resolve => require(['@/page/dictionary/coursesType/index'], resolve),
        },{
          title:"数据字典/心理机构导航",
          path: 'navigationMap',
          name: 'navigationMap',
          component: resolve => require(['@/page/dictionary/navigationMap'], resolve),
        },{
          title:"数据字典/学校库",
          path: 'school',
          name: 'school',
          component: resolve => require(['@/page/dictionary/school'], resolve),
        },{
          title:"数据字典/上报类型",
          path: 'reportType',
          name: 'reportType',
          component: resolve => require(['@/page/dictionary/reportType'], resolve),
        },{
          title:"数据字典/援助热线",
          path: 'hotline',
          name: 'hotline',
          component: resolve => require(['@/page/dictionary/hotline'], resolve),
        },  {
          path: 'role',
          name: 'role',
          component: resolve => require(['@/page/system/role/index'], resolve),
        }, {
          path: 'addRole/:id',
          name: 'addRole',
          component: resolve => require(['@/page/system/role/add'], resolve),
        }, {
          title:"系统用户",
          path: 'systemUser',
          name: 'systemUser',
          component: resolve => require(['@/page/system/user/index'], resolve),
        }, {
          path: 'systemOther',
          name: 'systemOther',
          component: resolve => require(['@/page/system/other/index'], resolve),
        },  {
          title:"课程管理",
          path: 'onlineCourses',
          name: 'onlineCourses',
          component: resolve => require(['@/page/courses/onlineCourses/index'], resolve),
        }, {
          title:"课程管理/编辑",
          path: 'onlineCoursesAdd/:id',
          name: 'onlineCoursesAdd',
          component: resolve => require(['@/page/courses/onlineCourses/add'], resolve),
        }, {
          title:"课时管理",
          path: 'coursesTime',
          name: 'coursesTime',
          component: resolve => require(['@/page/courses/coursesTime/index'], resolve),
        }, {
          title:"课时管理/编辑",
          path: 'coursesTimeAdd/:id',
          name: 'coursesTimeAdd',
          component: resolve => require(['@/page/courses/coursesTime/add'], resolve),
        }, {
          title:"其他配置",
          path: 'other',
          name: 'other',
          component: resolve => require(['@/page/other/index'], resolve),
        },
        
        {
          path: 'file',
          name: 'file',
          component: resolve => require(['@/page/system/file/index'], resolve),
        },
      ]
    },
    // }, {
    //   path: '/cityAndDistrict/:type',
    //   name: 'cityAndDistrict',
    //   component: resolve => require(['@/page/systemScreen/cityAndDistrict/index'], resolve),
    // },{
    //   path: '/platform',
    //   name: 'platform',
    //   component: resolve => require(['@/page/systemScreen/platform/index'], resolve),
    // }, {
    //   path: '/CDCScreen',
    //   name: 'CDCScreen',
    //   component: resolve => require(['@/page/systemScreen/CDCScreen/index'], resolve),
    // }, {
    //   path: '/platformScreenUpdate/:id',
    //   name: 'platformScreenUpdate',
    //   component: resolve => require(['@/page/systemScreen/platformScreenUpdate/index'], resolve),
    // },

    {
      path: '*',
      name: '404',
      component: resolve => require(['@/page/404'], resolve)
    },
  ]
})
// 解决同一路由重复点击出现的BUG
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
router.beforeEach((to, from, next) => {
  let access_token = localStorage.getItem('access_token')
  if (to.name != 'login' && to.name != 'index' && !access_token  && to.name != 'platform' && to.name != 'CDCScreen'&& to.name != 'cityAndDistrict'&& to.name != 'platformScreenUpdate') {
    next({
      path: 'login'
    });
  } else {
    next();
  }
});
// 暴露路由实例对象
export default router