import swal from 'sweetalert'
import * as CryptoJS from 'crypto-js'
let CryptoJSKey = "thanks,pig4cloud"
// 引入基本模板
let echarts = require('echarts/lib/echarts')
// 引入柱状图组件
require('echarts/lib/chart/pie')
require('echarts/lib/chart/bar')
require('echarts/lib/chart/line')
require('echarts/lib/chart/radar')
require('echarts/lib/chart/gauge')
// 引入提示框和title组件
require('echarts/lib/component/title')
require('echarts/lib/component/tooltip')
require("echarts/lib/component/toolbox")
require('echarts/lib/component/legend')
require("echarts/lib/component/dataZoom")
require("echarts/lib/component/markLine")
import {
  Message
} from 'element-ui'


const util = {
  baseUrl: '/test',
  // baseUrl: '/hhh',
  // baseUrl: '/pj',
  // baseUrl: '/120',
  //获取随机生成的验证码
  randomLenNum(len, date) {
    let random = ''
    random = Math.ceil(Math.random() * 100000000000000).toString().substr(0, len || 4)
    if (date) random = random + Date.now()
    return random
  },
  getToken(key) {
    var val = localStorage.getItem(key) || ''
    return val
  },
  // 防抖
  debounce(fn, wait) {
    var timer = null;
    return function () {
      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(fn, wait);
    }
  },
  // 强复制
  superCopy: obj => {
    return JSON.parse(JSON.stringify(obj))
  },
  // 消息提示
  message(type, text) {
    if (document.getElementsByClassName('el-message').length === 0) {
      if (type == 'suc') {
        Message({
          message: text,
          type: 'success'
        })
      } else if (type == 'war') {
        Message({
          message: text,
          type: 'warning'
        })
      } else if (type == 'err') {
        Message.error(text)
      }
    }
  },
  Timeweek() {
    var str = this.getNewTime('', '', 1)
    var Week = ['日', '一', '二', '三', '四', '五', '六'];
    str += ' 星期' + Week[new Date().getDay()];
    return str
  },
  getNewTime(val, date, type) {
    let time
    if (val) {
      time = new Date(val)
    } else {
      time = new Date()
    }
    let y = time.getFullYear()
    let m = time.getMonth() + 1
    let d = time.getDate()
    let h = time.getHours()
    let min = time.getMinutes()
    let s = time.getSeconds()
    if (type) {
      return y + '年' + m + '月' + d + '日'
    } else {
      return date ?
        y + '-' + m + '-' + d :
        (y < 10 ? '0' + y : y) + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d) + ' ' + (h < 10 ? '0' + h : h) + ':' + (min < 10 ? '0' + min : min) + ':' + (s < 10 ? '0' + s : s)
    }

  },
  format(date, fmt) {
    let formated = fmt
    const o = {
      'M+': date.getMonth() + 1, // 月份
      'd+': date.getDate(), // 日
      'h+': date.getHours(), // 小时
      'm+': date.getMinutes(), // 分
      's+': date.getSeconds(), // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
      'S+': date.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(formated)) formated = formated.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length))
    Object.keys(o).forEach((key) => {
      if (new RegExp(`(${key})`).test(formated)) {
        if (key === 'S+') {
          const start = `00${o[key]}`.length - 3
          formated = formated.replace(RegExp.$1, `00${o[key]}`.substr(start, RegExp.$1.length))
        }
        formated = formated.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[key]) : ((`00${o[key]}`).substr((`${o[key]}`).length)))
      }
    })

    return formated
  },
  // 只留年月日
  getNewDate(val) {
    return val.split(" ")[0]
  },
  //字符串去空
  trim(val) {
    return val.replace(/\s+/g, "").replace(/\.{1,}/g, '.').replace(/^\./g, '');
  },
  isNumber0(val) {
    return val.replace(/[^\d]/g, '')
  },
  isNumber1(val) {
    return val.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3')
  },
  isNumber2(val) {
    return val.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
  },
  isNumber3(val) {
    return val.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d\d).*$/, '$1$2.$3')
  },
  // 只能输入数字和英文
  isEnNum(val) {
    if (!val) return
    return val.replace(/[\W]/g, '')
  },
  // 不能输入中文和英文
  noEnNum(val) {
    if (!val) return
    return val.replace(/[\u4E00-\u9FA5A-Za-z]/g, '')
  },
  // 6位以上由大小写字母、特殊符号、数字组成的密码
  validPassword(rule, value, callback) {
    if (!value) {
      callback()
    } else {
      const reg = /^(?=.*[!@#$%^&_*,\.])[0-9a-zA-Z!@#$%^&_*,\.]{6,}$/
      if (reg.test(value)) {
        callback()
      } else {
        return callback(new Error('请输入6位以上含特殊符号的密码!'))
      }
    }
  },
  // 只能输入 数字 字母大小写  键盘上面的1-8字符
  inputSpecial(val) {
    if (!val) return
    return val.replace(/[^0-9a-zA-Z!@#$%^&*]/g, '')
  },

  //   无标无格线
  initCategory: (that, el, data, color1, color2) => {
    const myChart = echarts.init(document.getElementById(el));
    let option = {}
    option = {
      xAxis: {
        type: 'category',
        boundaryGap: false,
        show: false
      },
      yAxis: {
        type: "value",
        max: x => Math.round((x.max + (x.max - x.min) * 0.2) * 1000) / 1000,
        min: x => Math.round((x.min - (x.max - x.min) * 0.2) * 1000) / 1000,
        boundaryGap: [0, "100%"],
        show: false
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
          animation: false
        },
      },
      series: [{
        data: data,
        type: "line",
        symbol: 'circle',
        smooth: true,
        symbolSize: 0,
        itemStyle: {
          normal: {
            color: color1
          }
        },

        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: color2,
            },
            {
              offset: 1,
              color: color2
            }
          ])
        }
      }],
      grid: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        borderColor: "#aaaaaa"
      }
    };
    myChart.setOption(option);
    that.charts.push(myChart);
    window.addEventListener('resize', () => {
      myChart.resize();
    })
  },
  // 弧形折线图
  showInitCategory: (that, el, xAxis, data, color = '#0068cd', bgColor = '#8cc3fbc9', smooth = true, rotate = 0, YY, visibleNum) => {
    const myChart = echarts.init(document.getElementById(el));
    let option = {}
    let xAxisObj = {}
    if (rotate) {
      // x轴完全显示
      xAxisObj = {
        type: 'category',
        axisLine: {
          show: false,
        },
        data: xAxis,
        axisLabel: {
          interval: 0,
          rotate: rotate,
          color: YY ? "#fff" : "",

        }
      }
    } else {
      // x轴不完全显示
      xAxisObj = {
        type: 'category',
        data: xAxis,
      }
    }
    option = {
      xAxis: xAxisObj,
      yAxis: {
        type: "value",
        max: x => Math.round((x.max + (x.max - x.min) * 0.2) * 1000) / 1000,
        min: 0,
        boundaryGap: [0, "100%"],
        show: YY ? false : true
      },
      tooltip: {
        show: (YY || visibleNum) ? true : false,
        trigger: "axis",
        formatter(params) {
          // console.log(params,"params")
          return YY ? (params[0].axisValue + '份<br>' + that.dataEcharts[params[0].dataIndex].xxName + ": " + that.dataEcharts[params[0].dataIndex].xxScore + '人<br>' + that.dataEcharts[params[0].dataIndex].spName + ": " + that.dataEcharts[params[0].dataIndex].spScore + '人<br>' + that.dataEcharts[params[0].dataIndex].yyName + ": " + that.dataEcharts[params[0].dataIndex].yyScore + '%') : '测评次数 </br>' + params[0].data;

        },
        backgroundColor: visibleNum ? 'rgba(98,101,108)' : 'rgba(102,153,255,.6)',
        padding: [10, 15, 10, 15],
        fontSize: '14px',
        axisPointer: {
          type: 'shadow',

        },
        textStyle: {
          align: 'left'
        }
      },
      series: [{
        data: data,
        type: "line",
        smooth: smooth, //弧形
        itemStyle: {
          normal: {
            color: color,
            label: {
              show: (YY || visibleNum) ? false : true,
              // position: 'left',

            }
          }
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: bgColor
            },
            {
              offset: 1,
              color: bgColor
            }
          ])
        }
      }],
      grid: {
        left: 50,
        top: 45,
        right: 20,
        bottom: 30,
      }
    };

    myChart.setOption(option);
    that.charts.push(myChart);
    window.addEventListener('resize', () => {
      myChart.resize();
    })
  },
  // RF16折线图
  showRFEachrt: (that, el, xAxis, data, color = '#0068cd', smooth = true, rotate = 0) => {
    const myChart = echarts.init(document.getElementById(el));
    let option = {}
    let xAxisObj = {}
    if (rotate) {
      // x轴完全显示
      xAxisObj = {
        type: 'category',
        data: xAxis,
        axisLabel: {
          interval: 0,
          rotate: rotate,
        }
      }
    } else {
      // x轴不完全显示
      xAxisObj = {
        type: 'category',
        data: xAxis,
      }
    }
    option = {
      xAxis: xAxisObj,
      yAxis: {
        type: "value",
        max: 6,
        min: 0,
        splitNumber: 12,
        boundaryGap: [0, "100%"],
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "",
          animation: false
        },
      },
      series: [{
        data: data,
        type: "line",
        smooth: smooth, //弧形
        itemStyle: {
          normal: {
            color: color,
            label: {
              show: true
            }
          }
        },
        // areaStyle: {
        //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
        //       offset: 0,
        //       color: bgColor
        //     },
        //     {
        //       offset: 1,
        //       color: bgColor
        //     }
        //   ])
        // }
      }],
      grid: {
        left: 50,
        top: 45,
        right: 20,
        bottom: 60,
      }
    };

    myChart.setOption(option);
    that.charts.push(myChart);
    window.addEventListener('resize', () => {
      myChart.resize();
    })
  },
  //柱状图
  showColumCategory: (that, el, xAxis, data, section, YaxisLabel) => {
    const myChart = echarts.init(document.getElementById(el));
    const newData = util.getData(data, section) //data字符串数组
    let option = {}
    option = {
      xAxis: {
        type: 'category',
        data: xAxis
      },
      yAxis: {
        type: "value",
        max: 100,
        min: 0,
        splitNumber: 5,
        // boundaryGap: [0, 100],
        axisLabel: {
          formatter: function (value) {
            var texts = [];
            if (value == 0) {
              texts = '无';
            } else {
              texts = YaxisLabel[value / 20 - 1];
            }
            return texts;
          }
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
          animation: false
        },
        formatter: function (params) {
          let obj = params[0]
          return `${obj.axisValue}<br>${obj.seriesName} : ${data[obj.dataIndex]}`
        }
      },
      axisTick: {
        alignWithLabel: true
      },
      series: [{
        name: "测评分数",
        data: newData,
        barWidth: '40px',
        type: "bar",
        itemStyle: {
          normal: {
            color: function (params) {
              let value = params.value
              let colorArr = ["rgb(115, 222, 179)", "rgba(25, 128, 255, 0.8)", "rgba(254, 196, 2, 0.8)", "rgba(255, 99, 91, 0.8)"]
              if (value < 40) {
                return colorArr[0]
              } else if (value <= 60) {
                return colorArr[1]
              } else if (value <= 80) {
                return colorArr[2]
              } else {
                return colorArr[3]
              }
            },
            label: {
              show: false
            },
          }
        },
      }],
      grid: {
        left: 70,
        top: 45,
        right: 20,
        bottom: 60,
      }
    };
    myChart.setOption(option);
    that.charts.push(myChart);
    window.addEventListener('resize', () => {
      myChart.resize();
    })
  },
  //测评结果 单个颜色柱状图
  singleColumCategory: (that, el, xAxisData, YaxisData, color = 'RGB(79,178,197)') => {
    const myChart = echarts.init(document.getElementById(el));
    let option = {}
    option = {
      color: color,
      tooltip: {
        trigger: 'axis',
        axisPointer: { // 坐标轴指示器，坐标轴触发有效
          type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [{
        type: 'category',
        data: xAxisData,
        axisLabel: {
          interval: 0,
          rotate: 30,
        },
        axisTick: {
          alignWithLabel: true
        }
      }],
      yAxis: [{
        type: 'value',
        max: 12,
        min: 0,
        splitNumber: 12,

      }],
      series: [{
        name: '',
        type: 'bar',
        barWidth: '40%',
        data: YaxisData,
        markLine: {
          label: {
            position: "end" //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
          },
          data: [{
              silent: true, //鼠标悬停事件  true没有，false有
              lineStyle: { //警戒线的样式  ，虚实  颜色
                type: "dash",
                color: "#777777",
              },
              yAxis: 8,
            },
            {
              silent: true, //鼠标悬停事件  true没有，false有
              lineStyle: { //警戒线的样式  ，虚实  颜色
                type: "dash",
                color: "#777777",
              },
              yAxis: 3,
            },
          ]
        },
      }]
    };

    myChart.setOption(option);
    that.charts.push(myChart);
    window.addEventListener('resize', () => {
      myChart.resize();
    })
  },
  //测评结果 单个颜色雷达图
  RadarChart: (that, el, xAxisData, YaxisData, color = 'RGB(79,178,197)') => {
    const myChart = echarts.init(document.getElementById(el));
    let option = {}
    option = {
      tooltip: {},

      radar: {
        name: {
          textStyle: {
            color: '#fff',
            backgroundColor: color,
            borderRadius: 3,
            padding: [3, 5]
          }
        },
        splitLine: {
          lineStyle: {
            color: color
          }
        },
        axisLine: {
          lineStyle: {
            color: color
          }
        },
        indicator: xAxisData
      },
      series: [{
        name: '',
        type: 'radar',
        max: x => Math.round((x.max + (x.max - x.min) * 0.5) * 1000) / 1000,
        min: 0,
        tooltip: {
          trigger: 'item'
        },
        areaStyle: {
          opacity: 0.9,
          color: color
        },
        data: [{
          value: YaxisData,
          name: '',
          label: {
            show: true
          },
          areaStyle: {
            opacity: 0.9,
            color: color
          },
        }, ]
      }]
    };

    myChart.setOption(option);
    that.charts.push(myChart);
    window.addEventListener('resize', () => {
      myChart.resize();
    })
  },
  //饼状图
  pieChart: (that, el, xAxisData, YaxisData, color = 'RGB(79,178,197)') => {
    const myChart = echarts.init(document.getElementById(el));
    let option = {}
    option = {
      title: {
        text: '',
        subtext: '',
        left: 'center'
      },
      tooltip: {
        trigger: xAxisData,
        formatter: '{a} <br/>{b} : {c} ({d}%)'
      },
      legend: {},
      series: [{
        name: '',
        type: 'pie',
        radius: '55%',
        center: ['50%', '60%'],
        data: [{
            value: 335,
            name: '1'
          },
          {
            value: 310,
            name: '2'
          },
          {
            value: 234,
            name: '3'
          },
          {
            value: 135,
            name: '4'
          },
          {
            value: 1548,
            name: '5'
          }
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }]
    };

    myChart.setOption(option);
    that.charts.push(myChart);
    window.addEventListener('resize', () => {
      myChart.resize();
    })
  },
  // 心理咨询柱形图deta换算
  getData(data, section) {
    let arr = []
    let arrVal
    if (data && section) {
      // 实际数值
      data.find(item => {
        // 区间
        section.find((val, index) => {
          // 实际值小于正常值的最小值
          if (item < section[0].scoreFrom) {
            arrVal = {
              val: item,
              newVal: section[0].scoreFrom / 20 * item
            }
          }
          // 实际值在区间范围内
          else if (item >= val.scoreFrom && val.scoreTo > item) {
            let num = val.scoreTo - val.scoreFrom //当前区间差
            let num1 = item - val.scoreFrom //实际值与区间最小值的差
            if (section[0].scoreFrom == 0 && index == 0) {
              arrVal = {
                val: item,
                newVal: 40 * num1 / num
              }
            } else {
              arrVal = {
                val: item,
                newVal: 20 * num1 / num + 20 * (index + 1)
              }
            }
          }
          // 超出最大值
          else if (item >= section[3].scoreFrom) {
            arrVal = {
              val: item,
              newVal: (item - val.scoreTo) / 20 + 100
            }
          }
        })
        arr.push(arrVal)
      })
    }
    this.dataArr = arr
    return arr.map(v => {
      return v && v.newVal
    })

  },
  resizeCharts(that, ) {
    for (let i = 0; i < that.charts.length; i++) {
      const chart = that.charts[i];
      chart.resize();
    }
  },
  isSwal(text, fn, val) {
    swal(text, {
      buttons: {
        cancel: {
          text: '取消',
          className: 'swal_btn',
          visible: true
        },
        defeat: {
          text: '确定',
          className: ' el-button--primary swal_btn'
        }
      }
    }).then(value => {
      switch (value) {
        case 'defeat':
          fn(val)
          break
      }
    })
  },
  // 下载文件
  ExportCSV(res) {
    const blob = new Blob([res.data], {
      type: "application/vnd.ms-excel"
    })
    const filename = decodeURI((res.headers['content-disposition'] && res.headers['content-disposition'].split(';')[1].split('=')[1])) || 'file.xls'
    // 创建一个超链接，将文件流赋进去，然后实现这个超链接的单击事件
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const elink = document.createElement('a')
      elink.download = filename
      elink.style.display = 'none'
      elink.href = URL.createObjectURL(blob)
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href) // 释放URL 对象
      document.body.removeChild(elink)
    }
  },
  getTimeStamp(time) {
    let y = time.getFullYear()
    let m = time.getMonth() + 1
    let d = time.getDate()
    let h = time.getHours()
    let min = time.getMinutes()
    let s = time.getSeconds()
    return y + (m > 9 ? m : "0" + m) + (d > 9 ? d : "0" + d) + (h > 9 ? h : "0" + h) + (min > 9 ? min : "0" + min) + (s > 9 ? s : "0" + s)
  },
  // 日期显示(年月日)
  formatTimeG(date) {
    var arr = date.split(/[- : .]/);
    return [arr[0], arr[1], arr[2]].join('-')
  },
  //获取浏览器视口高度
  getHeight(minHeight) {
    let documentHeight = document.documentElement.offsetHeight
    let height = documentHeight - minHeight
    return height > 150 ? height : 150;
  },
  //AES加密传参
  Encrypt(word) {
    let key = CryptoJSKey;
    let iv = CryptoJSKey;

    key = CryptoJS.enc.Utf8.parse(key);
    iv = CryptoJS.enc.Utf8.parse(iv);

    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.ciphertext.toString()

  },
  // HmacMD5加密
  HMACShaEncrypt(word) {
    let key = CryptoJSKey;
    return CryptoJS.HmacMD5(word, key).toString().toUpperCase()

  },
  // 部分加密
  encryption(val) {
    let key = CryptoJS.enc.Latin1.parse("thanks,pig4cloud")
    let iv = key
    // 加密
    let encrypted = CryptoJS.AES.encrypt(
      val,
      key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
      })
    val = encrypted.toString()
    return val
  },
}

export default util