export const cache = (key, value, seconds) => {
  //缓存,默认有效期7天
  var timestamp = Date.parse(new Date()) / 1000
  if (key && value === null) {
    //删除缓存
    localStorage.removeItem(key);
  } else if (key && value) {
    var expire
    //设置缓存
    if (!seconds) {
        expire = timestamp + (3600 * 2)
    } else {
        expire = timestamp + seconds
    }
    value = value + "|" + expire
    localStorage.setItem(key, value);
  } else if (key) {
    //获取缓存
    var val = localStorage.getItem(key);
    var tmp = val.split("|")
    if (!tmp[1] || timestamp >= tmp[1]) {
      localStorage.removeItem(key)
      return false
    } else {
      return tmp[0]
    }
  } else {
    alert("key不能空")
  }
}
